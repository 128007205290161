/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import Grid from "~styles/Grid.jsx";
import * as T from "~styles/Typography.jsx";
import { Button, Layout, SEO } from "~components";

const StyleGuidePage = () => (
  <>
    <SEO noIndex />

    <Layout>
      <Grid node="section" _css={[tw`pt-24`]}>
        <ul css={[tw`col-span-12 pb-6 border-b`]}>
          {Array(4)
            .fill(null)
            .map((_, headingIndex) => {
              const key = `style-guide-heading-${headingIndex}`;

              return (
                <li key={key} tw="mt-6 mb-6">
                  <T.Head
                    key={key}
                    font={(headingIndex + 1).toString()}
                    level={(headingIndex + 1).toString()}
                  >
                    {`h${headingIndex + 1}`}
                  </T.Head>
                </li>
              );
            })}
        </ul>

        <ul css={[tw`col-span-12 pb-6 border-b`]}>
          {Array(3)
            .fill(null)
            .map((_, bodyIndex) => {
              const key = `style-guide-body-${bodyIndex}`;

              return (
                <li key={key} css={[tw`mt-6 mb-6`]}>
                  <T.Body
                    key={key}
                    font={(bodyIndex + 1).toString()}
                    level={(bodyIndex + 1).toString()}
                  >
                    {`b${bodyIndex + 1}`}
                  </T.Body>
                </li>
              );
            })}
        </ul>

        <div css={[tw`col-span-12 pt-6 pb-6 border-b`]}>
          <T.Caption>Caption</T.Caption>
        </div>

        <div css={[tw`col-span-12 pt-6 pb-6 border-b`]}>
          <Button _css={[tw`border py-2 px-6`]} text="Button Text" />
        </div>

        {/* // purge prevention // */}

        <div css={[tw`col-span-12 hidden`]}>
          <h1 css={[tw`text-blue-turq bg-blue-turq`]}>h1</h1>
          <h2 css={[tw`text-blue-bright bg-blue-bright`]}>h2</h2>
          <h3 css={[tw`text-blue-sky bg-blue-sky`]}>h3</h3>
          <h4 css={[tw`text-blue-faint bg-blue-faint`]}>h4</h4>
          <h5 css={[tw`text-blue-light bg-blue-light`]}>h5</h5>
          <h6 css={[tw`text-blue-med bg-blue-med`]}>h6</h6>
          <h6 css={[tw`text-blue-deep bg-blue-deep`]}>h6</h6>
          <h6 css={[tw`text-turqouise bg-turqouise`]}>h6</h6>

          <p>p</p>
          <p>
            <span>span</span>
          </p>
          <p>
            <b>b</b>
          </p>
          <p>
            <blockquote>blockquote</blockquote>
          </p>
          <p>
            <em>em</em>
          </p>
          <p>
            <i>i</i>
          </p>

          <ul>
            <li>li</li>
            <li>li</li>
            <li>li</li>
          </ul>

          <ul>
            <ol>ol</ol>
            <ol>ol</ol>
            <ol>ol</ol>
          </ul>

          <button type="button">button</button>
        </div>
      </Grid>
    </Layout>
  </>
);

export default StyleGuidePage;
